import { combineReducers } from 'redux';
import {
    ITC_PARAMS_FETCH_ERROR,
    ITC_PARAMS_FETCH_IN_PROGRESS,
    ITC_PARAMS_FETCH_SUCCESS,
    MARKETPLACE_PARAMS_FETCH_ERROR,
    MARKETPLACE_PARAMS_FETCH_IN_PROGRESS,
    MARKETPLACE_PARAMS_FETCH_SUCCESS,
    PARAMS_FETCH_ERROR,
    PARAMS_FETCH_IN_PROGRESS,
    PARAMS_FETCH_SUCCESS,
} from '../constants/params';

const params = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PARAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PARAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PARAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcParams = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_PARAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_PARAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ITC_PARAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const marketPlaceParams = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MARKETPLACE_PARAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MARKETPLACE_PARAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MARKETPLACE_PARAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    params,
    itcParams,
    marketPlaceParams,
});
