export const PARAMS_FETCH_IN_PROGRESS = 'PARAMS_FETCH_IN_PROGRESS';
export const PARAMS_FETCH_SUCCESS = 'PARAMS_FETCH_SUCCESS';
export const PARAMS_FETCH_ERROR = 'PARAMS_FETCH_ERROR';

export const ITC_PARAMS_FETCH_IN_PROGRESS = 'ITC_PARAMS_FETCH_IN_PROGRESS';
export const ITC_PARAMS_FETCH_SUCCESS = 'ITC_PARAMS_FETCH_SUCCESS';
export const ITC_PARAMS_FETCH_ERROR = 'ITC_PARAMS_FETCH_ERROR';

export const MARKETPLACE_PARAMS_FETCH_IN_PROGRESS = 'MARKETPLACE_PARAMS_FETCH_IN_PROGRESS';
export const MARKETPLACE_PARAMS_FETCH_SUCCESS = 'MARKETPLACE_PARAMS_FETCH_SUCCESS';
export const MARKETPLACE_PARAMS_FETCH_ERROR = 'MARKETPLACE_PARAMS_FETCH_ERROR';
