export const AUCTIONS_FETCH_IN_PROGRESS = 'AUCTIONS_FETCH_IN_PROGRESS';
export const AUCTIONS_FETCH_SUCCESS = 'AUCTIONS_FETCH_SUCCESS';
export const AUCTIONS_FETCH_ERROR = 'AUCTIONS_FETCH_ERROR';

export const FETCH_AUCTION_COLLECTION_NFT_S_SUCCESS = 'FETCH_AUCTIONS_COLLECTION_NFT_S_SUCCESS';
export const FETCH_AUCTIONS_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_AUCTIONS_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_AUCTIONS_COLLECTION_NFT_S_ERROR = 'FETCH_AUCTIONS_COLLECTION_NFT_S_ERROR';

export const FETCH_NFT_IN_PROGRESS = 'FETCH_NFT_IN_PROGRESS';
export const FETCH_NFT_SUCCESS = 'FETCH_NFT_SUCCESS';
export const FETCH_NFT_ERROR = 'FETCH_NFT_ERROR';
