export const FAUCET_DIALOG_SHOW = 'FAUCET_DIALOG_SHOW';
export const FAUCET_DIALOG_HIDE = 'FAUCET_DIALOG_HIDE';

export const FAUCET_SUCCESS_SET = 'FAUCET_SUCCESS_SET';

export const FAUCET_BALANCE_ADD_IN_PROGRESS = 'FAUCET_BALANCE_ADD_IN_PROGRESS';
export const FAUCET_BALANCE_ADD_SUCCESS = 'FAUCET_BALANCE_ADD_SUCCESS';
export const FAUCET_BALANCE_ADD_ERROR = 'FAUCET_BALANCE_ADD_ERROR';

export const FAUCET_CLAIM_FETCH_IN_PROGRESS = 'FAUCET_CLAIM_FETCH_IN_PROGRESS';
export const FAUCET_CLAIM_FETCH_SUCCESS = 'FAUCET_CLAIM_FETCH_SUCCESS';
export const FAUCET_CLAIM_FETCH_ERROR = 'FAUCET_CLAIM_FETCH_ERROR';

export const IBC_BALANCE_LIST_FETCH_IN_PROGRESS = 'IBC_BALANCE_LIST_FETCH_IN_PROGRESS';
export const IBC_BALANCE_LIST_FETCH_SUCCESS = 'IBC_BALANCE_LIST_FETCH_SUCCESS';
export const IBC_BALANCE_LIST_FETCH_ERROR = 'IBC_BALANCE_LIST_FETCH_ERROR';

export const FEE_GRANT_ALLOWANCE_IN_PROGRESS = 'FEE_GRANT_ALLOWANCE_IN_PROGRESS';
export const FEE_GRANT_ALLOWANCE_SUCCESS = 'FEE_GRANT_ALLOWANCE_SUCCESS';
export const FEE_GRANT_ALLOWANCE_ERROR = 'FEE_GRANT_ALLOWANCE_ERROR';
