export const VERIFY_TWITTER_ACTIVE_STEP_SET = 'VERIFY_TWITTER_ACTIVE_STEP_SET';
export const VERIFY_TWITTER_DIALOG_SHOW = 'VERIFY_TWITTER_DIALOG_SHOW';
export const VERIFY_TWITTER_DIALOG_HIDE = 'VERIFY_TWITTER_DIALOG_HIDE';

export const TWITTER_USERNAME_SET = 'TWITTER_USERNAME_SET';
export const TWEET_URL_SET = 'TWEET_URL_SET';

export const SUCCESS_DIALOG_SHOW = 'SUCCESS_DIALOG_SHOW';
export const SUCCESS_DIALOG_HIDE = 'SUCCESS_DIALOG_HIDE';

export const CAMPAIGNS_FETCH_IN_PROGRESS = 'CAMPAIGNS_FETCH_IN_PROGRESS';
export const CAMPAIGNS_FETCH_SUCCESS = 'CAMPAIGNS_FETCH_SUCCESS';
export const CAMPAIGNS_FETCH_ERROR = 'CAMPAIGNS_FETCH_ERROR';

export const CAMPAIGN_FETCH_IN_PROGRESS = 'CAMPAIGN_FETCH_IN_PROGRESS';
export const CAMPAIGN_FETCH_SUCCESS = 'CAMPAIGN_FETCH_SUCCESS';
export const CAMPAIGN_FETCH_ERROR = 'CAMPAIGN_FETCH_ERROR';

export const CAMPAIGN_LEADERBOARD_FETCH_IN_PROGRESS = 'CAMPAIGN_LEADERBOARD_FETCH_IN_PROGRESS';
export const CAMPAIGN_LEADERBOARD_FETCH_SUCCESS = 'CAMPAIGN_LEADERBOARD_FETCH_SUCCESS';
export const CAMPAIGN_LEADERBOARD_FETCH_ERROR = 'CAMPAIGN_LEADERBOARD_FETCH_ERROR';
