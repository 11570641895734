export const FETCH_OWNER_COLLECTIONS_IN_PROGRESS = 'FETCH_OWNER_COLLECTIONS_IN_PROGRESS';
export const FETCH_OWNER_COLLECTIONS_SUCCESS = 'FETCH_OWNER_COLLECTIONS_SUCCESS';
export const FETCH_OWNER_COLLECTIONS_ERROR = 'FETCH_OWNER_COLLECTIONS_ERROR';

export const FETCH_OWNER_NFT_S_IN_PROGRESS = 'FETCH_OWNER_NFT_S_IN_PROGRESS';
export const FETCH_OWNER_NFT_S_SUCCESS = 'FETCH_OWNER_NFT_S_SUCCESS';
export const FETCH_OWNER_NFT_S_ERROR = 'FETCH_OWNER_NFT_S_ERROR';

export const FETCH_OWNER_LISTINGS_IN_PROGRESS = 'FETCH_OWNER_LISTINGS_IN_PROGRESS';
export const FETCH_OWNER_LISTINGS_SUCCESS = 'FETCH_OWNER_LISTINGS_SUCCESS';
export const FETCH_OWNER_LISTINGS_ERROR = 'FETCH_OWNER_LISTINGS_ERROR';
