import { combineReducers } from 'redux';
import {
    CAMPAIGN_FETCH_ERROR,
    CAMPAIGN_FETCH_IN_PROGRESS,
    CAMPAIGN_FETCH_SUCCESS,
    CAMPAIGN_LEADERBOARD_FETCH_ERROR,
    CAMPAIGN_LEADERBOARD_FETCH_IN_PROGRESS,
    CAMPAIGN_LEADERBOARD_FETCH_SUCCESS,
    CAMPAIGNS_FETCH_ERROR,
    CAMPAIGNS_FETCH_IN_PROGRESS,
    CAMPAIGNS_FETCH_SUCCESS,
    SUCCESS_DIALOG_HIDE,
    SUCCESS_DIALOG_SHOW,
    TWEET_URL_SET,
    TWITTER_USERNAME_SET,
    VERIFY_TWITTER_ACTIVE_STEP_SET,
    VERIFY_TWITTER_DIALOG_HIDE,
    VERIFY_TWITTER_DIALOG_SHOW,
} from '../constants/campaign';
import { DISCONNECT_SET } from '../constants/account';
import { DEFAULT_ORDER, DEFAULT_SORT_BY, DEFAULT_TOTAL } from '../config';

const verifyTwitter = (state = {
    activeStep: 0,
    dialog: false,
}, action) => {
    switch (action.type) {
    case VERIFY_TWITTER_ACTIVE_STEP_SET:
        return {
            ...state,
            activeStep: action.step,
        };
    case VERIFY_TWITTER_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
        };
    case VERIFY_TWITTER_DIALOG_HIDE:
        return {
            dialog: false,
            activeStep: 0,
        };
    default:
        return state;
    }
};

export const twitterUserName = (state = '', action) => {
    switch (action.type) {
    case TWITTER_USERNAME_SET:
        return action.value;
    case VERIFY_TWITTER_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

export const tweetURL = (state = '', action) => {
    switch (action.type) {
    case TWEET_URL_SET:
        return action.value;
    case VERIFY_TWITTER_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const successDialog = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case SUCCESS_DIALOG_SHOW:
        return {
            open: true,
            message: action.message,
        };
    case SUCCESS_DIALOG_HIDE:
        return {
            dialog: false,
            message: '',
        };
    default:
        return state;
    }
};

const campaigns = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGNS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const campaign = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAMPAIGN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CAMPAIGN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const leaderBoard = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LEADERBOARD_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_LEADERBOARD_FETCH_SUCCESS:
        return {
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    case CAMPAIGN_LEADERBOARD_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    verifyTwitter,
    twitterUserName,
    tweetURL,
    successDialog,
    campaigns,
    campaign,
    leaderBoard,
});
