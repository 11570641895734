import { combineReducers } from 'redux';
import {
    FILTER_SET,
    ON_SALE_IN_SEARCH_SET,
    ON_SALE_IN_SET,
    PRICE_RANGE_COIN_SET,
    PRICE_RANGE_SET,
    SEARCH_IN_PROGRESS_SET,
    SEARCH_SET,
} from '../constants/filters';
import {
    COLLECTIONS_FETCH_ERROR,
    COLLECTIONS_FETCH_SUCCESS,
    NFT_LIST_OWNED_FETCH_ERROR,
    NFT_LIST_OWNED_FETCH_SUCCESS,
} from '../constants/createAssets/mintNft';
import { LISTINGS_FETCH_ERROR, LISTINGS_FETCH_SUCCESS } from '../constants/createAssets/listing';
import { tokensList } from '../utils/defaultOptions';

const search = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case SEARCH_SET:
        return {
            ...state,
            value: action.value,
        };
    case SEARCH_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    case COLLECTIONS_FETCH_SUCCESS:
    case COLLECTIONS_FETCH_ERROR:
    case NFT_LIST_OWNED_FETCH_SUCCESS:
    case NFT_LIST_OWNED_FETCH_ERROR:
    case LISTINGS_FETCH_SUCCESS:
    case LISTINGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const filter = (state = {
    show: false,
}, action) => {
    switch (action.type) {
    case FILTER_SET:
        return {
            show: action.value,
        };
    default:
        return state;
    }
};

const onSaleIn = (state = {
    value: new Set(),
    search: '',
}, action) => {
    switch (action.type) {
    case ON_SALE_IN_SET:
        return {
            ...state,
            value: action.value,
        };
    case ON_SALE_IN_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const priceRange = (state = {
    value: tokensList && tokensList.length && tokensList[0],
    range: [0, 50000],
    min: 0,
    max: 50000,
}, action) => {
    switch (action.type) {
    case PRICE_RANGE_COIN_SET:
        return {
            ...state,
            value: action.value,
        };
    case PRICE_RANGE_SET:
        return {
            ...state,
            range: action.value,
        };
    default:
        return state;
    }
};

export default combineReducers({
    search,
    filter,
    onSaleIn,
    priceRange,
});
