import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    ADD_TWITTER_ACCOUNT_ERROR,
    ADD_TWITTER_ACCOUNT_IN_PROGRESS,
    ADD_TWITTER_ACCOUNT_SUCCESS,
    AMOUNT_VALUE_SET,
    CONFIRM_DEPOSIT_SET,
    CONFIRM_WITHDRAW_SET,
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    DEPOSITE_DIALOG_HIDE,
    DEPOSITE_DIALOG_SHOW,
    DISCONNECT_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
    VERIFY_TWEET_ERROR,
    VERIFY_TWEET_IN_PROGRESS,
    VERIFY_TWEET_SUCCESS,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../../constants/account';
import Axios from 'axios';
import {
    ACCESS_TOKEN_URL,
    ADD_TWITTER_ACCOUNT_URL,
    CONNECT_ACCOUNT_URL,
    PROFILE_DETAILS_URL,
    urlVerifyAccount,
    VERIFY_TWEET_URL,
} from '../../constants/url';
import { EMPTY_VALUES_SET } from '../../constants/createAssets';

const connectBCAccountInProgress = () => {
    return {
        type: CONNECT_BC_ACCOUNT_IN_PROGRESS,
    };
};

const connectBCAccountSuccess = (value) => {
    return {
        type: CONNECT_BC_ACCOUNT_SUCCESS,
        value,
    };
};

const connectBCAccountError = (message) => {
    return {
        type: CONNECT_BC_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const connectBCAccount = (data, cb) => (dispatch) => {
    dispatch(connectBCAccountInProgress());

    Axios.post(CONNECT_ACCOUNT_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(connectBCAccountSuccess(res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            dispatch(connectBCAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const verifyAccountInProgress = () => {
    return {
        type: VERIFY_ACCOUNT_IN_PROGRESS,
    };
};

const verifyAccountSuccess = (message, value) => {
    return {
        type: VERIFY_ACCOUNT_SUCCESS,
        message,
        value,
    };
};

const verifyAccountError = (message) => {
    return {
        type: VERIFY_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const verifyAccount = (userId, data, cb) => (dispatch) => {
    dispatch(verifyAccountInProgress());

    const url = urlVerifyAccount(userId);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(verifyAccountSuccess('Success', res.data && res.data.result['access_token']));
            localStorage.setItem('acToken_of_studio', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_of_studio', res.data && res.data.result['refresh_token']);
            cb(null);
        })
        .catch((error) => {
            dispatch(verifyAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.message
                    ? error.response.data.message.message
                    : error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            cb(error);
        });
};

const fetchAccessTokenInProgress = () => {
    return {
        type: ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchAccessTokenSuccess = (message, value) => {
    return {
        type: ACCESS_TOKEN_FETCH_SUCCESS,
        message,
        value,
    };
};

const fetchAccessTokenError = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_ERROR,
        message,
    };
};

export const fetchAccessToken = (cb) => (dispatch) => {
    dispatch(fetchAccessTokenInProgress());

    const data = {
        refreshToken: localStorage.getItem('rfToken_of_studio'),
    };

    Axios.post(ACCESS_TOKEN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            localStorage.setItem('acToken_of_studio', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_of_studio', res.data && res.data.result['refresh_token']);
            dispatch(fetchAccessTokenSuccess('Success', res.data && res.data.result['access_token']));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAccessTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const addTwitterAccountInProgress = () => {
    return {
        type: ADD_TWITTER_ACCOUNT_IN_PROGRESS,
    };
};

const addTwitterAccountSuccess = (value) => {
    return {
        type: ADD_TWITTER_ACCOUNT_SUCCESS,
        value,
    };
};

const addTwitterAccountError = (message) => {
    return {
        type: ADD_TWITTER_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const addTwitterAccount = (data, cb) => (dispatch) => {
    dispatch(addTwitterAccountInProgress());

    Axios.post(ADD_TWITTER_ACCOUNT_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(addTwitterAccountSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addTwitterAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const verifyTweetInProgress = () => {
    return {
        type: VERIFY_TWEET_IN_PROGRESS,
    };
};

const verifyTweetSuccess = (value) => {
    return {
        type: VERIFY_TWEET_SUCCESS,
        value,
    };
};

const verifyTweetError = (message) => {
    return {
        type: VERIFY_TWEET_ERROR,
        message,
        variant: 'error',
    };
};

export const verifyTweet = (data, cb) => (dispatch) => {
    dispatch(verifyTweetInProgress());

    Axios.post(VERIFY_TWEET_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(verifyTweetSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(verifyTweetError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchProfileDetailsInProgress = () => {
    return {
        type: PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: PROFILE_DETAILS_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProfileDetails = () => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_studio'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const setEmptyValue = () => {
    return {
        type: EMPTY_VALUES_SET,
    };
};

export const setDisconnect = () => {
    return {
        type: DISCONNECT_SET,
    };
};

export const showDepositeDialog = (value) => {
    return {
        type: DEPOSITE_DIALOG_SHOW,
        value,
    };
};

export const hideDepositeDialog = () => {
    return {
        type: DEPOSITE_DIALOG_HIDE,
    };
};

export const showWithdrawDialog = (value) => {
    return {
        type: WITHDRAW_DIALOG_SHOW,
        value,
    };
};

export const hideWithdrawDialog = () => {
    return {
        type: WITHDRAW_DIALOG_HIDE,
    };
};

export const setDepositConfirm = (value) => {
    return {
        type: CONFIRM_DEPOSIT_SET,
        value,
    };
};

export const setWithdrawConfirm = (value) => {
    return {
        type: CONFIRM_WITHDRAW_SET,
        value,
    };
};

export const setAmountValue = (value) => {
    return {
        type: AMOUNT_VALUE_SET,
        value,
    };
};
