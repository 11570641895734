import { combineReducers } from 'redux';
import {
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR,
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    ADD_INTERACTION_SET, ASSET_INFO_VIDEO_ASRELEASE_NUMBER_SET, ASSET_INFO_VIDEO_ASRELEASE_TYPE_SET, ASSET_INFO_VIDEO_DESCRIPTION_SET, ASSET_INFO_VIDEO_SOURCE_FILE_SET, ASSET_INFO_VIDEO_TITLE_SET,
    EDIT_INTERACTION_CARD_HIDE,
    EDIT_INTERACTION_CARD_SHOW,
    INTERACTIVE_SEARCH_VALUE_SET,
    INTERACTIVE_TABS_VALUE_SET,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    MY_INTERACTIVE_VIDEOS_FETCH_ERROR,
    MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    PUBLISH_SCHEDULE_START_TIME_SET,
    PUBLISH_SCHEDULE_TAB_SET,
    PUBLISH_VISIBILITY_TAB_SET,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
} from '../../constants/interactiveVideos';
import newFile from './new';
import overlayFile from './overlay';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import { INTERACTIVE_VIDEO_FETCH_SUCCESS } from '../../constants/interactiveVideos/new';

const interactiveTab = (state = 'all', action) => {
    if (action.type === INTERACTIVE_TABS_VALUE_SET) {
        return action.value;
    }

    return state;
};

const interactiveSearch = (state = '', action) => {
    if (action.type === INTERACTIVE_SEARCH_VALUE_SET) {
        return action.value;
    }

    return state;
};

const myInteractiveVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case MY_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const interVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const activeInterVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const upcomingInterVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publishVisibilityTab = (state = 'public', action) => {
    if (action.type === PUBLISH_VISIBILITY_TAB_SET) {
        return action.value;
    }

    return state;
};

const publishScheduleTab = (state = 'now', action) => {
    if (action.type === PUBLISH_SCHEDULE_TAB_SET) {
        return action.value;
    }

    return state;
};

const publishScheduleStartTime = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case PUBLISH_SCHEDULE_START_TIME_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    default:
        return state;
    }
};

const editInteractionCard = (state = {
    open: false,
    value: {},
    index: null,
}, action) => {
    switch (action.type) {
    case EDIT_INTERACTION_CARD_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
            index: action.index,
        };
    case EDIT_INTERACTION_CARD_HIDE:
        return {
            ...state,
            open: false,
            value: {},
            index: null,
        };

    default:
        return state;
    }
};

const addInteraction = (state = false, action) => {
    if (action.type === ADD_INTERACTION_SET) {
        return action.value;
    }

    return state;
};

// asset info
const videoTitle = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_TITLE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.title,
        };
    // case CLEAR_ITC_MODULE_SET:
    //     return {
    //         value: '',
    //         valid: true,
    //     };
    default:
        return state;
    }
};

const videoDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.description,
        };
    // case CLEAR_ITC_MODULE_SET:
    //     return {
    //         value: '',
    //         valid: true,
    //     };
    default:
        return state;
    }
};

const videoSourceFile = (state = {
    value: '',
    valid: true,
    type: null,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_SOURCE_FILE_SET:
        return {
            value: action.value,
            valid: action.valid,
            type: action.typp,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.video_thumbnail,
        };
    // case CLEAR_ITC_MODULE_SET:
    //     return {
    //         value: '',
    //         valid: true,
    //         type: null,
    //     };
    default:
        return state;
    }
};

const videoASReleaseType = (state = {
    value: '',
    valid: false,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_ASRELEASE_TYPE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            value: action.result && action.result.agni_sakshi_release && action.result.agni_sakshi_release.type,
            valid: !!(action.result && action.result.agni_sakshi_release && action.result.agni_sakshi_release.type !== ''),
        };
    default:
        return state;
    }
};

const videoASReleaseNumber = (state = {
    value: '',
    valid: false,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_ASRELEASE_NUMBER_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            value: action.result && action.result.agni_sakshi_release && action.result.agni_sakshi_release.number,
            valid: !!(action.result && action.result.agni_sakshi_release && action.result.agni_sakshi_release.number !== ''),
        };
    default:
        return state;
    }
};

export default combineReducers({
    interactiveTab,
    interactiveSearch,
    newFile,
    overlayFile,
    myInteractiveVideos,
    interVideos,
    activeInterVideos,
    upcomingInterVideos,

    publishVisibilityTab,
    publishScheduleTab,
    publishScheduleStartTime,

    editInteractionCard,
    addInteraction,

    videoTitle,
    videoDescription,
    videoSourceFile,
    videoASReleaseType,
    videoASReleaseNumber,
});
