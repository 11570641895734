export const COLLECTIONS_FETCH_IN_PROGRESS = 'COLLECTIONS_FETCH_IN_PROGRESS';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_ERROR = 'COLLECTIONS_FETCH_ERROR';
export const CANCEL_FETCH_COLLECTIONS = 'CANCEL_FETCH_COLLECTIONS';

export const ALL_COLLECTIONS_FETCH_IN_PROGRESS = 'ALL_COLLECTIONS_FETCH_IN_PROGRESS';
export const ALL_COLLECTIONS_FETCH_SUCCESS = 'ALL_COLLECTIONS_FETCH_SUCCESS';
export const ALL_COLLECTIONS_FETCH_ERROR = 'ALL_COLLECTIONS_FETCH_ERROR';
export const CANCEL_FETCH_ALL_COLLECTIONS = 'CANCEL_FETCH_ALL_COLLECTIONS';

export const COLLECTION_FETCH_IN_PROGRESS = 'COLLECTION_FETCH_IN_PROGRESS';
export const COLLECTION_FETCH_SUCCESS = 'COLLECTION_FETCH_SUCCESS';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';

export const COLLECTION_ADD_IN_PROGRESS = 'COLLECTION_ADD_IN_PROGRESS';
export const COLLECTION_ADD_SUCCESS = 'COLLECTION_ADD_SUCCESS';
export const COLLECTION_ADD_ERROR = 'COLLECTION_ADD_ERROR';

export const COLLECTION_UPDATE_IN_PROGRESS = 'COLLECTION_UPDATE_IN_PROGRESS';
export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS';
export const COLLECTION_UPDATE_ERROR = 'COLLECTION_UPDATE_ERROR';

export const MINT_NFT_ADD_IN_PROGRESS = 'MINT_NFT_ADD_IN_PROGRESS';
export const MINT_NFT_ADD_SUCCESS = 'MINT_NFT_ADD_SUCCESS';
export const MINT_NFT_ADD_ERROR = 'MINT_NFT_ADD_ERROR';

export const MINT_NFT_IN_PROGRESS = 'MINT_NFT_IN_PROGRESS';
export const MINT_NFT_SUCCESS = 'MINT_NFT_SUCCESS';
export const MINT_NFT_ERROR = 'MINT_NFT_ERROR';

export const NFT_LIST_FETCH_IN_PROGRESS = 'NFT_LIST_FETCH_IN_PROGRESS';
export const NFT_LIST_FETCH_SUCCESS = 'NFT_LIST_FETCH_SUCCESS';
export const NFT_LIST_FETCH_ERROR = 'NFT_LIST_FETCH_ERROR';

export const NFT_LIST_OWNED_FETCH_IN_PROGRESS = 'NFT_LIST_OWNED_FETCH_IN_PROGRESS';
export const NFT_LIST_OWNED_FETCH_SUCCESS = 'NFT_LIST_OWNED_FETCH_SUCCESS';
export const NFT_LIST_OWNED_FETCH_ERROR = 'NFT_LIST_OWNED_FETCH_ERROR';

export const NFT_FETCH_IN_PROGRESS = 'NFT_FETCH_IN_PROGRESS';
export const NFT_FETCH_SUCCESS = 'NFT_FETCH_SUCCESS';
export const NFT_FETCH_ERROR = 'NFT_FETCH_ERROR';

export const NFT_TRANSFER_IN_PROGRESS = 'NFT_TRANSFER_IN_PROGRESS';
export const NFT_TRANSFER_SUCCESS = 'NFT_TRANSFER_SUCCESS';
export const NFT_TRANSFER_ERROR = 'NFT_TRANSFER_ERROR';

export const COLLECTION_CONFIRM_DIALOG_SHOW = 'COLLECTION_CONFIRM_DIALOG_SHOW';
export const COLLECTION_CONFIRM_DIALOG_HIDE = 'COLLECTION_CONFIRM_DIALOG_HIDE';

export const TRANSFER_NFT_CONFIRM_DIALOG_SHOW = 'TRANSFER_NFT_CONFIRM_DIALOG_SHOW';
export const TRANSFER_NFT_CONFIRM_DIALOG_HIDE = 'TRANSFER_NFT_CONFIRM_DIALOG_HIDE';

export const BURN_NFT_CONFIRM_DIALOG_SHOW = 'BURN_NFT_CONFIRM_DIALOG_SHOW';
export const BURN_NFT_CONFIRM_DIALOG_HIDE = 'BURN_NFT_CONFIRM_DIALOG_HIDE';

export const MINT_NFT_CONFIRM_DIALOG_SHOW = 'MINT_NFT_CONFIRM_DIALOG_SHOW';
export const MINT_NFT_CONFIRM_DIALOG_HIDE = 'MINT_NFT_CONFIRM_DIALOG_HIDE';

export const FETCH_LISTED_COLLECTION_NFT_S_SUCCESS = 'FETCH_LISTED_COLLECTION_NFT_S_SUCCESS';
export const FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_LISTED_COLLECTION_NFT_S_ERROR = 'FETCH_LISTED_COLLECTION_NFT_S_ERROR';

export const FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS = 'FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS';
export const FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR = 'FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR';
