export const pollOptionList = [{
    value: 'mcq',
    label: 'MCQ',
}, {
    value: 'poll',
    label: 'Poll',
}, {
    value: 'qa',
    label: 'QA',
}];
