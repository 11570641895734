export const ACTIVE_STEP_SET = 'ACTIVE_STEP_SET';

export const ASSET_TITLE_SET = 'ASSET_TITLE_SET';
export const SOURCE_TYPE_SET = 'SOURCE_TYPE_SET';
export const TWEET_ID_SET = 'TWEET_ID_SET';
export const CATEGORY_SET = 'CATEGORY_SET';
export const DESCRIPTION_SET = 'DESCRIPTION_SET';
export const TAGS_SET = 'TAGS_SET';
export const COLLECTION_SET = 'COLLECTION_SET';
export const RECIPIENT_ADDRESS_SET = 'RECIPIENT_ADDRESS_SET';
export const TRANSFER_STATUS_SET = 'TRANSFER_STATUS_SET';
export const RECIEPENT_ADDRESS_STATUS_SET = 'RECIEPENT_ADDRESS_STATUS_SET';
export const TOKEN_VALUE_SET = 'TOKEN_VALUE_SET';
export const TOKEN_PRICE_SET = 'TOKEN_PRICE_SET';
export const EXTENSIBLE_STATUS_SET = 'EXTENSIBLE_STATUS_SET';
export const NSFW_STATUS_SET = 'NSFW_STATUS_SET';
export const ROYALTY_SHARE_STATUS_SET = 'ROYALTY_SHARE_STATUS_SET';
export const ROYALTY_SHARE_SET = 'ROYALTY_SHARE_SET';

export const SCHEMA_VALUES_SET = 'SCHEMA_VALUES_SET';

export const DATE_DIALOG_SHOW = 'DATE_DIALOG_SHOW';
export const DATE_DIALOG_HIDE = 'DATE_DIALOG_HIDE';
export const DATE_RANGE_SET = 'DATE_RANGE_SET';
export const START_DATE_SET = 'START_DATE_SET';
export const END_DATE_SET = 'END_DATE_SET';
export const START_TIME_SET = 'START_TIME_SET';
export const END_TIME_SET = 'END_TIME_SET';

export const EMPTY_VALUES_SET = 'EMPTY_VALUES_SET';

export const PLAYER_SET = 'PLAYER_SET';
export const PREVIEW_DIALOG_SHOW = 'PREVIEW_DIALOG_SHOW';
export const PREVIEW_DIALOG_HIDE = 'PREVIEW_DIALOG_HIDE';

export const CREATE_COLLECTION_DIALOG_SHOW = 'CREATE_COLLECTION_DIALOG_SHOW';
export const CREATE_COLLECTION_DIALOG_HIDE = 'CREATE_COLLECTION_DIALOG_HIDE';
export const CREATE_COLLECTION_NAME_SET = 'CREATE_COLLECTION_NAME_SET';
export const CREATE_COLLECTION_SYMBOL_SET = 'CREATE_COLLECTION_SYMBOL_SET';
export const CREATE_COLLECTION_DESCRIPTION_SET = 'CREATE_COLLECTION_DESCRIPTION_SET';
export const CREATE_COLLECTION_JSON_SCHEMA_SET = 'CREATE_COLLECTION_JSON_SCHEMA_SET';
export const CREATE_COLLECTION_IMAGE_URL_SET = 'CREATE_COLLECTION_IMAGE_URL_SET';
export const CREATE_COLLECTION_ACTIVE_STEP_SET = 'CREATE_COLLECTION_ACTIVE_STEP_SET';

export const ASSET_ADD_IN_PROGRESS = 'ASSET_ADD_IN_PROGRESS';
export const ASSET_ADD_SUCCESS = 'ASSET_ADD_SUCCESS';
export const ASSET_ADD_ERROR = 'ASSET_ADD_ERROR';

export const ASSET_UPDATE_IN_PROGRESS = 'ASSET_UPDATE_IN_PROGRESS';
export const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
export const ASSET_UPDATE_ERROR = 'ASSET_UPDATE_ERROR';

export const ASSET_FETCH_IN_PROGRESS = 'ASSET_FETCH_IN_PROGRESS';
export const ASSET_FETCH_SUCCESS = 'ASSET_FETCH_SUCCESS';
export const ASSET_FETCH_ERROR = 'ASSET_FETCH_ERROR';

export const ASSET_DELETE_IN_PROGRESS = 'ASSET_DELETE_IN_PROGRESS';
export const ASSET_DELETE_SUCCESS = 'ASSET_DELETE_SUCCESS';
export const ASSET_DELETE_ERROR = 'ASSET_DELETE_ERROR';

export const ASSET_UPLOAD_IN_PROGRESS = 'ASSET_UPLOAD_IN_PROGRESS';
export const ASSET_UPLOAD_SUCCESS = 'ASSET_UPLOAD_SUCCESS';
export const ASSET_UPLOAD_ERROR = 'ASSET_UPLOAD_ERROR';

export const ASSET_UPLOAD_SET = 'ASSET_UPLOAD_SET';
export const ASSET_UPLOAD_CANCEL_SET = 'ASSET_UPLOAD_CANCEL_SET';
export const ASSET_UPLOAD_PROGRESS_SET = 'ASSET_UPLOAD_PROGRESS_SET';

export const UPLOAD_FILE_CANCEL_SET = 'UPLOAD_FILE_CANCEL_SET';
export const UPLOAD_FILE_ERROR_MESSAGE_SET = 'UPLOAD_FILE_ERROR_MESSAGE_SET';

export const UPLOAD_FILES_DIALOG_SHOW = 'UPLOAD_FILES_DIALOG_SHOW';
export const UPLOAD_FILES_DIALOG_HIDE = 'UPLOAD_FILES_DIALOG_HIDE';

export const TAGS_FETCH_IN_PROGRESS = 'TAGS_FETCH_IN_PROGRESS';
export const TAGS_FETCH_SUCCESS = 'TAGS_FETCH_SUCCESS';
export const TAGS_FETCH_ERROR = 'TAGS_FETCH_ERROR';

export const ASSET_CARDS_FETCH_IN_PROGRESS = 'ASSET_CARDS_IN_PROGRESS';
export const ASSET_CARDS_FETCH_SUCCESS = 'ASSET_CARDS_SUCCESS';
export const ASSET_CARDS_FETCH_ERROR = 'ASSET_CARDS_ERROR';

export const SCHEMA_FETCH_IN_PROGRESS = 'SCHEMA_FETCH_IN_PROGRESS';
export const SCHEMA_FETCH_SUCCESS = 'SCHEMA_FETCH_SUCCESS';
export const SCHEMA_FETCH_ERROR = 'SCHEMA_FETCH_ERROR';

export const SCHEMA_SET = 'SCHEMA_SET';

export const AVATAR_UPLOAD_IN_PROGRESS = 'AVATAR_UPLOAD_IN_PROGRESS';
export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS';
export const AVATAR_UPLOAD_ERROR = 'AVATAR_UPLOAD_ERROR';

export const BULK_MINT_SET = 'BULK_MINT_SET';
export const SUFFIX_VALUE_SET = 'SUFFIX_VALUE_SET';
export const SUFFIX_COUNT_SET = 'SUFFIX_COUNT_SET';
export const MEDIA_URL_SET = 'MEDIA_URL_SET';
export const PREVIEW_URL_SET = 'PREVIEW_URL_SET';
export const MINT_COUNT_TAB_SET = 'MINT_COUNT_TAB_SET';
export const MINT_COUNT_SET = 'MINT_COUNT_SET';
export const MULTIPLE_MINT_TAB_SET = 'MULTIPLE_MINT_TAB_SET';
