import { config } from '../config';
import inputIcon from '../assets/interactive/input_text.svg';
import mcqIcon from '../assets/interactive/mcq.svg';
import pollIcon from '../assets/interactive/poll.svg';
import seekIcon from '../assets/interactive/seek.svg';
import delegateIcon from '../assets/interactive/delegate_fungibles.svg';
import voteIcon from '../assets/interactive/vote_proposals.svg';

export const tokensList = [{
    network: config,
    value: 'uflix',
    label: 'FLIX',
}];

export const customSchema = {
    name: 'Custom Schema',
    schema: {
        id: 'custom_schema',
        properties: {},
        required: [],
        type: 'object',
    },
};

export const noSchema = {
    name: 'No Schema',
};

export const schemaPropertyTypes = [{
    name: 'string',
}, {
    name: 'boolean',
}, {
    name: 'number',
}, {
    name: 'date',
}, {
    name: 'textarea',
}];

export const durationOptions = [{
    name: '10S',
    value: 10,
}, {
    name: '20S',
    value: 20,
}, {
    name: '30S',
    value: 30,
}, {
    name: '40S',
    value: 40,
}, {
    name: 'infinite',
    value: 'infinite',
}];

export const typeOptions = [{
    img: pollIcon,
    name: 'POLL',
    value: 'POLL',
}];

export const typeOptionsAll = [{
    img: inputIcon,
    name: 'Input Answers',
    value: 'Input Answers',
    coming: true,
}, {
    img: mcqIcon,
    name: 'MCQ',
    value: 'MCQ',
    coming: true,
}, {
    img: pollIcon,
    name: 'POLL',
    value: 'POLL',
}, {
    img: seekIcon,
    name: 'SEEK',
    value: 'SEEK',
    coming: true,
}, {
    img: delegateIcon,
    name: 'Delegate fungibles',
    value: 'Delegate fungibles',
    coming: true,
}, {
    img: voteIcon,
    name: 'Vote on Proposals',
    value: 'Vote on Proposals',
    coming: true,
}];

export const suffixOptions = [{
    name: 'NFT - 1',
    value: ' - ',
}, {
    name: 'NFT #1',
    value: ' #',
}, {
    name: 'NFT (1)',
    value: ' ()',
}, {
    name: 'No Suffix',
    value: null,
}];

export const alphabeticalOrder = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
