export const INTERACTIVE_TABS_VALUE_SET = 'INTERACTIVE_TABS_VALUE_SET';
export const INTERACTIVE_SEARCH_VALUE_SET = 'INTERACTIVE_SEARCH_VALUE_SET';

export const INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEOS_FETCH_ERROR = 'INTERACTIVE_VIDEOS_FETCH_ERROR';

export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const MY_INTERACTIVE_VIDEOS_FETCH_ERROR = 'MY_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const PUBLISH_VISIBILITY_TAB_SET = 'PUBLISH_VISIBILITY_TAB_SET';
export const PUBLISH_SCHEDULE_TAB_SET = 'PUBLISH_SCHEDULE_TAB_SET';
export const PUBLISH_SCHEDULE_START_TIME_SET = 'PUBLISH_SCHEDULE_START_TIME_SET';

export const EDIT_INTERACTION_CARD_SHOW = 'EDIT_INTERACTION_CARD_SHOW';
export const EDIT_INTERACTION_CARD_HIDE = 'EDIT_INTERACTION_CARD_HIDE';
export const ADD_INTERACTION_SET = 'ADD_INTERACTION_SET';

// asset info
export const ASSET_INFO_VIDEO_TITLE_SET = 'ASSET_INFO_VIDEO_TITLE_SET';
export const ASSET_INFO_VIDEO_DESCRIPTION_SET = 'ASSET_INFO_VIDEO_DESCRIPTION_SET';
export const ASSET_INFO_VIDEO_SOURCE_FILE_SET = 'ASSET_INFO_VIDEO_SOURCE_FILE_SET';
export const ASSET_INFO_VIDEO_ASRELEASE_TYPE_SET = 'ASSET_INFO_VIDEO_ASRELEASE_TYPE_SET';
export const ASSET_INFO_VIDEO_ASRELEASE_NUMBER_SET = 'ASSET_INFO_VIDEO_ASRELEASE_NUMBER_SET';
