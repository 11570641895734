import { combineReducers } from 'redux';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';
import {
    AUCTIONS_FETCH_ERROR,
    AUCTIONS_FETCH_IN_PROGRESS,
    AUCTIONS_FETCH_SUCCESS,
    FETCH_AUCTION_COLLECTION_NFT_S_SUCCESS,
    FETCH_AUCTIONS_COLLECTION_NFT_S_ERROR,
    FETCH_AUCTIONS_COLLECTION_NFT_S_IN_PROGRESS,
    FETCH_NFT_ERROR,
    FETCH_NFT_IN_PROGRESS,
    FETCH_NFT_SUCCESS,
} from '../constants/auctions';

const list = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case AUCTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case AUCTIONS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case AUCTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const collectionAuctions = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_AUCTIONS_COLLECTION_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_AUCTION_COLLECTION_NFT_S_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case FETCH_AUCTIONS_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nftDetails = (state = {
    result: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NFT_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    }
    case FETCH_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    list,
    collectionAuctions,
    nftDetails,
});
