export const SEARCH_IN_PROGRESS_SET = 'SEARCH_IN_PROGRESS_SET';
export const SEARCH_SET = 'SEARCH_SET';

export const FILTER_SET = 'FILTER_SET';

export const ON_SALE_IN_SET = 'ON_SALE_IN_SET';
export const ON_SALE_IN_SEARCH_SET = 'ON_SALE_IN_SEARCH_SET';

export const PRICE_RANGE_SET = 'PRICE_RANGE_SET';
export const PRICE_RANGE_COIN_SET = 'PRICE_RANGE_COIN_SET';
