export const COLLECTION_TAB_VALUE_SET = 'COLLECTION_TAB_VALUE_SET';

export const JSON_TAB_SWITCH_SET = 'JSON_TAB_SWITCH_SET';

export const LISTED_SEARCH_IN_PROGRESS_SET = 'LISTED_SEARCH_IN_PROGRESS_SET';
export const LISTED_SEARCH_SET = 'LISTED_SEARCH_SET';
export const NON_LISTED_SEARCH_SET = 'NON_LISTED_SEARCH_SET';
export const NON_LISTED_SEARCH_IN_PROGRESS_SET = 'NON_LISTED_SEARCH_IN_PROGRESS_SET';

export const LISTED_FILTER_SET = 'LISTED_FILTER_SET';

export const LISTED_ON_SALE_IN_SET = 'LISTED_ON_SALE_IN_SET';
export const LISTED_ON_SALE_IN_SEARCH_SET = 'LISTED_ON_SALE_IN_SEARCH_SET';

export const LISTED_PRICE_RANGE_SET = 'LISTED_PRICE_RANGE_SET';
export const LISTED_PRICE_RANGE_COIN_SET = 'LISTED_PRICE_RANGE_COIN_SET';

export const UPDATE_COLLECTION_SET = 'UPDATE_COLLECTION_SET';

export const DOWNLOAD_COLLECTION_NFT_S_IN_PROGRESS = 'DOWNLOAD_COLLECTION_NFT_S_IN_PROGRESS';
export const DOWNLOAD_COLLECTION_NFT_S_SUCCESS = 'DOWNLOAD_COLLECTION_NFT_S_SUCCESS';
export const DOWNLOAD_COLLECTION_NFT_S_ERROR = 'DOWNLOAD_COLLECTION_NFT_S_ERROR';

export const COLLECTION_EXPORT_POPOVER_SHOW = 'COLLECTION_EXPORT_POPOVER_SHOW';
export const COLLECTION_EXPORT_POPOVER_HIDE = 'COLLECTION_EXPORT_POPOVER_HIDE';

export const COLLECTION_STATS_FETCH_SUCCESS = 'COLLECTION_STATS_FETCH_SUCCESS';
export const COLLECTION_STATS_FETCH_IN_PROGRESS = 'COLLECTION_STATS_FETCH_IN_PROGRESS';
export const COLLECTION_STATS_FETCH_ERROR = 'COLLECTION_STATS_FETCH_ERROR';

export const COLLECTION_TRANSFER_DIALOG_SHOW = 'COLLECTION_TRANSFER_DIALOG_SHOW';
export const COLLECTION_TRANSFER_DIALOG_CONFIRMATION_SHOW = 'COLLECTION_TRANSFER_DIALOG_CONFIRMATION_SHOW';
export const COLLECTION_TRANSFER_DIALOG_HIDE = 'COLLECTION_TRANSFER_DIALOG_HIDE';
export const COLLECTION_TRANSFER_ADDRESS_SET = 'COLLECTION_TRANSFER_ADDRESS_SET';

export const COLLECTION_TRANSFER_IN_PROGRESS = 'COLLECTION_TRANSFER_IN_PROGRESS';
export const COLLECTION_TRANSFER_SUCCESS = 'COLLECTION_TRANSFER_SUCCESS';
export const COLLECTION_TRANSFER_ERROR = 'COLLECTION_TRANSFER_ERROR';
