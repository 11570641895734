import { combineReducers } from 'redux';
import {
    ASSETS_FETCH_ERROR,
    ASSETS_FETCH_IN_PROGRESS,
    ASSETS_FETCH_SUCCESS,
    BULK_DE_LIST_DIALOG_HIDE,
    BULK_DE_LIST_DIALOG_SHOW,
    BULK_EDIT_HIDE,
    BULK_EDIT_LIST_SET,
    BULK_EDIT_SHOW,
    BULK_LIST_DIALOG_HIDE,
    BULK_LIST_DIALOG_SHOW,
    BULK_TRANSFER_ADDRESS_SET,
    BULK_TRANSFER_DIALOG_HIDE,
    BULK_TRANSFER_DIALOG_SHOW,
    BURN_NFT_SET,
    CONFIRM_BULK_LIST_DIALOG_HIDE,
    CONFIRM_BULK_LIST_DIALOG_SHOW,
    DOWNLOAD_NON_MINTED_ASSETS_ERROR,
    DOWNLOAD_NON_MINTED_ASSETS_IN_PROGRESS,
    DOWNLOAD_NON_MINTED_ASSETS_SUCCESS,
    LIBRARY_TAB_VALUE_SET,
    LIST_QUICK_VIEW_HIDE,
    LIST_QUICK_VIEW_SHOW,
    MENU_ACTION_DIALOG_HIDE,
    MENU_ACTION_DIALOG_SHOW,
    MENU_POPOVER_HIDE,
    MENU_POPOVER_SHOW,
    NON_MINTED_EXPORT_POPOVER_HIDE,
    NON_MINTED_EXPORT_POPOVER_SHOW,
    WINDOW_RESIZE_SET,
} from '../constants/library';
import { DISCONNECT_SET } from '../constants/account';
import { DE_LISTED_DIALOG_HIDE } from '../constants/createAssets/listing';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';

const windowSize = (state = 4, action) => {
    if (action.type === WINDOW_RESIZE_SET) {
        return action.value;
    }

    return state;
};

const libraryTabValue = (state = 'non-minted', action) => {
    if (action.type === LIBRARY_TAB_VALUE_SET) {
        return action.value;
    }

    return state;
};

const menuPopover = (state = {
    anchorEl: null,
    open: false,
    asset: {},
    list: {},
}, action) => {
    switch (action.type) {
    case MENU_POPOVER_SHOW:
        return {
            anchorEl: action.anchorEl,
            open: true,
            asset: action.value,
            list: action.list || {},
        };
    case MENU_POPOVER_HIDE:
        return {
            ...state,
            anchorEl: null,
            open: false,
        };
    case MENU_ACTION_DIALOG_HIDE:
        return {
            ...state,
            asset: {},
        };
    case DE_LISTED_DIALOG_HIDE:
        return {
            ...state,
            list: {},
        };
    default:
        return state;
    }
};

const quickView = (state = {
    open: false,
    asset: {},
}, action) => {
    switch (action.type) {
    case LIST_QUICK_VIEW_SHOW:
        return {
            open: true,
            asset: action.value,
        };
    case LIST_QUICK_VIEW_HIDE:
        return {
            open: false,
            asset: {},
        };
    default:
        return state;
    }
};

const menuActionDialog = (state = {
    open: false,
    value: '',
}, action) => {
    switch (action.type) {
    case MENU_ACTION_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
        };
    case MENU_ACTION_DIALOG_HIDE:
        return {
            open: false,
            value: '',
        };
    default:
        return state;
    }
};

const burnNFT = (state = '', action) => {
    if (action.type === BURN_NFT_SET) {
        return action.value;
    } else if (action.type === MENU_ACTION_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const assets = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ASSETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case ASSETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
        };
    default:
        return state;
    }
};

const downloadNonMintedAssets = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DOWNLOAD_NON_MINTED_ASSETS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DOWNLOAD_NON_MINTED_ASSETS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case DOWNLOAD_NON_MINTED_ASSETS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const nonMintedExportPopover = (state = {
    anchorEl: null,
    open: false,
}, action) => {
    switch (action.type) {
    case NON_MINTED_EXPORT_POPOVER_SHOW:
        return {
            anchorEl: action.anchorEl,
            open: true,
        };
    case NON_MINTED_EXPORT_POPOVER_HIDE:
    case DOWNLOAD_NON_MINTED_ASSETS_SUCCESS:
    case DOWNLOAD_NON_MINTED_ASSETS_ERROR:
        return {
            ...state,
            anchorEl: null,
            open: false,
        };
    default:
        return state;
    }
};

const bulkEdit = (state = {
    open: false,
    value: [],
    addresses: {},
    listDialog: false,
    listConfirm: false,
    deListDialog: false,
    transferDialog: false,
}, action) => {
    switch (action.type) {
    case BULK_EDIT_SHOW:
        return {
            ...state,
            open: true,
        };
    case BULK_EDIT_HIDE:
        return {
            ...state,
            open: false,
        };
    case BULK_EDIT_LIST_SET:
        return {
            ...state,
            value: action.value,
        };
    case BULK_LIST_DIALOG_SHOW:
        return {
            ...state,
            listDialog: true,
        };
    case BULK_LIST_DIALOG_HIDE:
        return {
            ...state,
            listDialog: false,
            listConfirm: false,
        };
    case CONFIRM_BULK_LIST_DIALOG_SHOW:
        return {
            ...state,
            listConfirm: true,
        };
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return {
            ...state,
            listDialog: false,
            listConfirm: false,
        };
    case BULK_DE_LIST_DIALOG_SHOW:
        return {
            ...state,
            deListDialog: true,
        };
    case BULK_DE_LIST_DIALOG_HIDE:
        return {
            ...state,
            deListDialog: false,
        };
    case BULK_TRANSFER_DIALOG_SHOW:
        return {
            ...state,
            transferDialog: true,
        };
    case BULK_TRANSFER_DIALOG_HIDE:
        return {
            ...state,
            transferDialog: false,
            addresses: {},
        };
    case BULK_TRANSFER_ADDRESS_SET:
        return {
            ...state,
            addresses: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    windowSize,
    libraryTabValue,
    menuPopover,
    quickView,
    menuActionDialog,
    burnNFT,
    assets,
    downloadNonMintedAssets,
    bulkEdit,
    nonMintedExportPopover,
});
