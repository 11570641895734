export const CONNECT_ACCOUNT_IN_PROGRESS = 'CONNECT_ACCOUNT_IN_PROGRESS';
export const CONNECT_ACCOUNT_SUCCESS = 'CONNECT_ACCOUNT_SUCCESS';
export const CONNECT_ACCOUNT_ERROR = 'CONNECT_ACCOUNT_ERROR';

export const KEPLR_ACCOUNT_KEYS_SET = 'KEPLR_ACCOUNT_KEYS_SET';

export const AMINO_SIGN_SUCCESS = 'AMINO_SIGN_SUCCESS';

export const PROTO_BUF_SIGN_IN_PROGRESS = 'PROTO_BUF_SIGN_IN_PROGRESS';
export const PROTO_BUF_SIGN_SUCCESS = 'PROTO_BUF_SIGN_SUCCESS';
export const PROTO_BUF_SIGN_ERROR = 'PROTO_BUF_SIGN_ERROR';

export const CONNECT_BC_ACCOUNT_IN_PROGRESS = 'CONNECT_BC_ACCOUNT_IN_PROGRESS';
export const CONNECT_BC_ACCOUNT_SUCCESS = 'CONNECT_BC_ACCOUNT_SUCCESS';
export const CONNECT_BC_ACCOUNT_ERROR = 'CONNECT_BC_ACCOUNT_ERROR';

export const VERIFY_ACCOUNT_IN_PROGRESS = 'VERIFY_ACCOUNT_IN_PROGRESS';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';

export const ACCESS_TOKEN_FETCH_IN_PROGRESS = 'ACCESS_TOKEN_FETCH_IN_PROGRESS';
export const ACCESS_TOKEN_FETCH_SUCCESS = 'ACCESS_TOKEN_FETCH_SUCCESS';
export const ACCESS_TOKEN_FETCH_ERROR = 'ACCESS_TOKEN_FETCH_ERROR';

export const ADD_TWITTER_ACCOUNT_IN_PROGRESS = 'ADD_TWITTER_ACCOUNT_IN_PROGRESS';
export const ADD_TWITTER_ACCOUNT_SUCCESS = 'ADD_TWITTER_ACCOUNT_SUCCESS';
export const ADD_TWITTER_ACCOUNT_ERROR = 'ADD_TWITTER_ACCOUNT_ERROR';

export const VERIFY_TWEET_IN_PROGRESS = 'VERIFY_TWEET_IN_PROGRESS';
export const VERIFY_TWEET_SUCCESS = 'VERIFY_TWEET_SUCCESS';
export const VERIFY_TWEET_ERROR = 'VERIFY_TWEET_ERROR';

export const PROFILE_DETAILS_FETCH_IN_PROGRESS = 'PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_ERROR = 'PROFILE_DETAILS_FETCH_ERROR';

export const BALANCE_FETCH_IN_PROGRESS = 'BALANCE_FETCH_IN_PROGRESS';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_ERROR = 'BALANCE_FETCH_ERROR';

export const ALLOWANCES_FETCH_IN_PROGRESS = 'ALLOWANCES_FETCH_IN_PROGRESS';
export const ALLOWANCES_FETCH_SUCCESS = 'ALLOWANCES_FETCH_SUCCESS';
export const ALLOWANCES_FETCH_ERROR = 'ALLOWANCES_FETCH_ERROR';

export const TX_SIGN_AND_BROAD_CAST_IN_PROGRESS = 'TX_SIGN_AND_BROAD_CAST_IN_PROGRESS';
export const TX_SIGN_AND_BROAD_CAST_SUCCESS = 'TX_SIGN_AND_BROAD_CAST_SUCCESS';
export const TX_SIGN_AND_BROAD_CAST_ERROR = 'TX_SIGN_AND_BROAD_CAST_ERROR';

export const TX_HASH_FETCH_IN_PROGRESS = 'TX_HASH_FETCH_CAST_IN_PROGRESS';
export const TX_HASH_FETCH_SUCCESS = 'TX_HASH_FETCH_CAST_SUCCESS';
export const TX_HASH_FETCH_ERROR = 'TX_HASH_FETCH_CAST_ERROR';

export const TX_HASH_IN_PROGRESS_FALSE_SET = 'TX_HASH_IN_PROGRESS_FALSE_SET';

export const DISCONNECT_SET = 'DISCONNECT_SET';

export const USER_NAME_SET = 'USER_NAME_SET';

export const DEPOSITE_DIALOG_SHOW = 'DEPOSITE_DIALOG_SHOW';
export const DEPOSITE_DIALOG_HIDE = 'DEPOSITE_DIALOG_HIDE';
export const WITHDRAW_DIALOG_SHOW = 'WITHDRAW_DIALOG_SHOW';
export const WITHDRAW_DIALOG_HIDE = 'WITHDRAW_DIALOG_HIDE';
export const CONFIRM_DEPOSIT_SET = 'CONFIRM_DEPOSIT_SET';
export const CONFIRM_WITHDRAW_SET = 'CONFIRM_WITHDRAW_SET';

export const AMOUNT_VALUE_SET = 'AMOUNT_VALUE_SET';
