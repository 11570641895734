export const ITC_MODULE_TAB_VALUE_SET = 'ITC_MODULE_TAB_VALUE_SET';
export const ITC_LIST_SEARCH_VALUE_SET = 'ITC_LIST_SEARCH_VALUE_SET';
export const INTERACTION_TYPE_TAB_SET = 'INTERACTION_TYPE_TAB_SET';
export const CLAIM_TYPE_TAB_SET = 'CLAIM_TYPE_TAB_SET';

export const TITLE_VALUE_SET = 'TITLE_VALUE_SET';
export const DESCRIPTION_VALUE_SET = 'DESCRIPTION_VALUE_SET';
export const SCHEDULE_START_DATE_SET = 'SCHEDULE_START_DATE_SET';
export const SCHEDULE_END_DATE_SET = 'SCHEDULE_END_DATE_SET';
export const INPUT_COLLECTION_VALUE_SET = 'INPUT_COLLECTION_VALUE_SET';
export const SEARCH_INPUT_COLLECTION_VALUE_SET = 'SEARCH_INPUT_COLLECTION_VALUE_SET';
export const TOKENS_PER_CLAIM_VALUE_SET = 'TOKENS_PER_CLAIM_VALUE_SET';
export const MAX_ALLOWED_CLAIMS_VALUE_SET = 'MAX_ALLOWED_CLAIMS_VALUE_SET';

export const FT_TOKEN_VALUE_SET = 'FT_TOKEN_VALUE_SET';
export const FT_AMOUNT_VALUE_SET = 'FT_AMOUNT_VALUE_SET';
export const DISTRIBUTION_TYPE_TAB_SET = 'DISTRIBUTION_TYPE_TAB_SET';
export const FT_END_DATE_SET = 'FT_END_DATE_SET';

export const NFT_TITLE_VALUE_SET = 'NFT_TITLE_VALUE_SET';
export const NFT_SOURCE_FILE_SET = 'NFT_SOURCE_FILE_SET';
export const NFT_DESCRIPTION_VALUE_SET = 'NFT_DESCRIPTION_VALUE_SET';
export const NFT_PREVIEW_IMAGE_SET = 'NFT_PREVIEW_IMAGE_SET';
export const NFT_SELECT_COLLECTIONS_SET = 'NFT_SELECT_COLLECTIONS_SET';
export const NFT_ROYALTY_SHARE_SET = 'NFT_ROYALTY_SHARE_SET';
export const NFT_TRANSFERABILITY_SET = 'NFT_TRANSFERABILITY_SET';
export const NFT_EXTENSIBILITY_SET = 'NFT_EXTENSIBILITY_SET';
export const NFT_NSFW_SET = 'NFT_NSFW_SET';

export const CLEAR_ITC_MODULE_SET = 'CLEAR_ITC_MODULE_SET';

export const STATS_SEARCH_VALUE_SET = 'STATS_SEARCH_VALUE_SET';
export const STATS_SORT_BY_VALUE_SET = 'STATS_SORT_BY_VALUE_SET';

export const ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_LISTING_FETCH_ERROR = 'ITC_CAMPAIGNS_LISTING_FETCH_ERROR';

export const ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_ERROR = 'ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_ERROR';

export const ITC_CAMPAIGNS_LIVE_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGNS_LIVE_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_LIVE_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_LIVE_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_LIVE_LISTING_FETCH_ERROR = 'ITC_CAMPAIGNS_LIVE_LISTING_FETCH_ERROR';

export const ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_ERROR = 'ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_ERROR';

export const ITC_DEPOSIT_AMOUNT_SET = 'ITC_DEPOSIT_AMOUNT_SET';

export const ITC_DEPOSIT_DIALOG_SHOW = 'ITC_DEPOSIT_DIALOG_SHOW';
export const ITC_DEPOSIT_DIALOG_HIDE = 'ITC_DEPOSIT_DIALOG_HIDE';
export const ITC_STOP_DIALOG_SHOW = 'ITC_STOP_DIALOG_SHOW';
export const ITC_STOP_DIALOG_HIDE = 'ITC_STOP_DIALOG_HIDE';

export const ITC_CAMPAIGNS_STATS_FETCH_SUCCESS = 'ITC_CAMPAIGNS_STATS_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_STATS_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_STATS_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_STATS_FETCH_ERROR = 'ITC_CAMPAIGNS_STATS_FETCH_ERROR';
