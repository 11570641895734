import flixIcon from './assets/tokens/flix.svg';

export const config = {
    API_URL: 'https://dev-api-agni-sakshi.omniflix.studio',
    RPC_URL: 'https://rpc.devnet-alpha.omniflix.network',
    REST_URL: 'https://api.devnet-alpha.omniflix.network',
    DATA_LAYER: 'https://data-layer-dev.omniflix.studio',
    CHAIN_ID: 'devnet-alpha-3',
    CHAIN_NAME: 'OmniFlix Hub Devnet',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    CHANNEL_ID: '668560392ea90294af149f48',
    GAS_PRICE_STEP_LOW: 0.001,
    GAS_PRICE_STEP_AVERAGE: 0.0025,
    GAS_PRICE_STEP_HIGH: 0.025,
};

export const ACTIVITY_URL = 'https://activity-api-dev-alpha.omniflix.studio';
export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';

export const DEFAULT_TOTAL = 20;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_SEARCH = null;
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
export const TRANSACTION_SET_TIME_OUT = 3000;

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const OMNIFLIX_NUCLEUS_URL = 'https://nucleus-dev.omniflix.live';
export const RUNNER_URL = 'https://dev-runner-cbcod.omniflix.studio';
export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const IPFS_REFERENCE_PATH = 'ipfs://';
export const INSYNC_URL = 'https://dev.omniflix.co';
export const STREAM_PAY_URL = 'https://sp.omniflix.network';
export const MINT_ACCESS = 'https://forms.gle/Aj3oc2fbcoCTX9KF7';
export const GAS_PRICE_STEP_LOW = 0.001;
export const GAS_PRICE_STEP_AVERAGE = 0.0025;
export const GAS_PRICE_STEP_HIGH = 0.025;

export const gas = {
    LIST_NFT: 200000,
    MINT_NFT: 300000,
    ITC_CLAIM: 200000,
};

export const socialLinks = {
    TWITTER: 'https://twitter.com/OmniFlixNetwork',
    TELEGRAM: 'https://t.me/OmniFlixChat',
    DISCORD: 'https://discord.com/invite/6gdQ4yZSTC',
    GITHUB: 'https://github.com/OmniFlix',
    WEBSITE: 'https://omniflix.network/',
    YOUTUBE: 'https://www.youtube.com/OmniFlixNetwork',
};

export const appsLinks = {
    NUCLEUS: OMNIFLIX_NUCLEUS_URL,
    IN_SYNC: INSYNC_URL,
    TV: 'https://bb.omniflix.tv',
};

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: GAS_PRICE_STEP_LOW,
                average: GAS_PRICE_STEP_AVERAGE,
                high: GAS_PRICE_STEP_HIGH,
            },
        },
    ],
    coinType: 118,
    features: ['ibc-transfer'],
};

export const FaucetList = [{
    name: config.COIN_DENOM,
    chain_id: config.CHAIN_ID,
    chain: 'omniflix',
    icon: flixIcon,
    denom: config.COIN_MINIMAL_DENOM,
}];
