import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';
import { combineReducers } from 'redux';
import {
    CHANNEL_ID_SET,
    FETCH_CHANNELS_ERROR,
    FETCH_CHANNELS_IN_PROGRESS,
    FETCH_CHANNELS_SUCCESS,
} from '../constants/channels';

const channelsList = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CHANNELS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CHANNELS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    case FETCH_CHANNELS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const channelID = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CHANNEL_ID_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    channelsList,
    channelID,
});
