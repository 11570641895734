import { combineReducers } from 'redux';
import {
    ANSWER_DESCRIPTION_SET, INTERACTION_TYPE_SET,
    NETWORK_VALUE_SET, POLL_TYPE_VALUE_SET,
    TOKENS_TO_DELEGATE_SET,
} from '../../constants/interactiveVideos/overlay';
import { tokensList } from '../../utils/defaultOptions';

const interactionType = (state = {
    value: 'poll',
}, action) => {
    switch (action.type) {
    case INTERACTION_TYPE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const pollTypeValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case POLL_TYPE_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const answerDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ANSWER_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    default:
        return state;
    }
};

const networkValue = (state = {
    value: null,
    options: tokensList,
}, action) => {
    switch (action.type) {
    case NETWORK_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const tokensToDelegate = (state = {
    value: null,
}, action) => {
    switch (action.type) {
    case TOKENS_TO_DELEGATE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    interactionType,
    pollTypeValue,
    answerDescription,
    networkValue,
    tokensToDelegate,
});
