import { combineReducers } from 'redux';
import {
    CREATE_POLL_DIALOG_HIDE,
    CREATE_POLL_DIALOG_SHOW,
    EXCLUDE_INFO_SET,
    INCLUDE_INFO_SET,
    POLL_DESCRIPTION_SET,
    POLL_END_DATE_SET,
    POLL_OPTION_SET,
    POLL_OPTIONS_SET,
    POLL_QUESTION_SET,
    POLL_START_DATE_SET,
} from '../../constants/polls';
import { pollOptionList } from '../../utils/dummy';

const createPollDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case CREATE_POLL_DIALOG_SHOW:
        return {
            open: true,
        };
    case CREATE_POLL_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const option = (state = {
    value: '',
    list: pollOptionList,
}, action) => {
    switch (action.type) {
    case POLL_OPTION_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const question = (state = '', action) => {
    switch (action.type) {
    case POLL_QUESTION_SET:
        return action.value;
    default:
        return state;
    }
};

const description = (state = '', action) => {
    switch (action.type) {
    case POLL_DESCRIPTION_SET:
        return action.value;
    default:
        return state;
    }
};

const options = (state = {
    value: '',
    list: [],
}, action) => {
    switch (action.type) {
    case POLL_OPTIONS_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const includeInfo = (state = {
    status: false,
    value: [],
}, action) => {
    switch (action.type) {
    // case SPLIT_INFO_STATUS_SET: {
    //     if (action.value && state.value && Object.keys(state.value).length === 0) {
    //         return {
    //             status: action.value,
    //             value: [{
    //                 address: '',
    //                 weight: '',
    //             }],
    //         };
    //     } else if (!action.value) {
    //         return {
    //             status: action.value,
    //             value: [],
    //         };
    //     } else {
    //         return {
    //             ...state,
    //             status: action.value,
    //         };
    //     }
    // }
    case INCLUDE_INFO_SET:
        return {
            ...state,
            value: action.value,
        };
    case CREATE_POLL_DIALOG_HIDE:
        return {
            status: false,
            value: [],
        };
    default:
        return state;
    }
};

const excludeInfo = (state = {
    status: false,
    value: [],
}, action) => {
    switch (action.type) {
    // case SPLIT_INFO_STATUS_SET: {
    //     if (action.value && state.value && Object.keys(state.value).length === 0) {
    //         return {
    //             status: action.value,
    //             value: [{
    //                 address: '',
    //                 weight: '',
    //             }],
    //         };
    //     } else if (!action.value) {
    //         return {
    //             status: action.value,
    //             value: [],
    //         };
    //     } else {
    //         return {
    //             ...state,
    //             status: action.value,
    //         };
    //     }
    // }
    case EXCLUDE_INFO_SET:
        return {
            ...state,
            value: action.value,
        };
    case CREATE_POLL_DIALOG_HIDE:
        return {
            status: false,
            value: [],
        };
    default:
        return state;
    }
};

const pollStartDate = (state = null, action) => {
    switch (action.type) {
    case POLL_START_DATE_SET:
        return action.value;
    default:
        return state;
    }
};

const pollEndDate = (state = '', action) => {
    switch (action.type) {
    case POLL_END_DATE_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    createPollDialog,
    option,
    question,
    description,
    options,
    includeInfo,
    excludeInfo,
    pollStartDate,
    pollEndDate,
});
