import { combineReducers } from 'redux';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    ADD_TWITTER_ACCOUNT_ERROR,
    ADD_TWITTER_ACCOUNT_IN_PROGRESS,
    ADD_TWITTER_ACCOUNT_SUCCESS,
    AMINO_SIGN_SUCCESS,
    AMOUNT_VALUE_SET,
    CONFIRM_DEPOSIT_SET,
    CONFIRM_WITHDRAW_SET,
    CONNECT_ACCOUNT_ERROR,
    CONNECT_ACCOUNT_IN_PROGRESS,
    CONNECT_ACCOUNT_SUCCESS,
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    DEPOSITE_DIALOG_HIDE,
    DEPOSITE_DIALOG_SHOW,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
    VERIFY_TWEET_ERROR,
    VERIFY_TWEET_IN_PROGRESS,
    VERIFY_TWEET_SUCCESS,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../../constants/account';
import BCDetails from './BCDetails';
import IBCTokens from './IBCTokens';

const connection = (state = {
    inProgress: false,
    address: '',
    addressInProgress: true,
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            addressInProgress: true,
        };
    case CONNECT_BC_ACCOUNT_IN_PROGRESS:
    case VERIFY_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONNECT_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
                action.value[0] && action.value[0].address,
        };
    case CONNECT_ACCOUNT_ERROR:
    case AMINO_SIGN_SUCCESS:
        return {
            ...state,
            inProgress: false,
            addressInProgress: false,
        };
    case CONNECT_BC_ACCOUNT_ERROR:
    case CONNECT_BC_ACCOUNT_SUCCESS:
    case VERIFY_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: localStorage.getItem('acToken_of_studio'),
}, action) => {
    switch (action.type) {
    case ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCESS_TOKEN_FETCH_SUCCESS:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const twitterAccount = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ADD_TWITTER_ACCOUNT_IN_PROGRESS:
    case VERIFY_TWEET_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_TWITTER_ACCOUNT_SUCCESS:
    case VERIFY_TWEET_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ADD_TWITTER_ACCOUNT_ERROR:
    case VERIFY_TWEET_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
    case VERIFY_TWEET_SUCCESS:
    case ADD_TWITTER_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const depositeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DEPOSITE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DEPOSITE_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const withDrawDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case WITHDRAW_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case WITHDRAW_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const amountValue = (state = null, action) => {
    switch (action.type) {
    case AMOUNT_VALUE_SET:
        return action.value;
    case DEPOSITE_DIALOG_HIDE:
    case WITHDRAW_DIALOG_HIDE:
        return null;
    default:
        return state;
    }
};

const confirmDeposit = (state = false, action) => {
    switch (action.type) {
    case CONFIRM_DEPOSIT_SET:
        return action.value;
    case DEPOSITE_DIALOG_HIDE:
        return false;

    default:
        return state;
    }
};

const confirmWithdraw = (state = false, action) => {
    switch (action.type) {
    case CONFIRM_WITHDRAW_SET:
        return action.value;
    case WITHDRAW_DIALOG_HIDE:
        return false;

    default:
        return state;
    }
};

export default combineReducers({
    connection,
    token,
    twitterAccount,
    profileDetails,
    bc: BCDetails,
    depositeDialog,
    withDrawDialog,
    amountValue,
    ibc: IBCTokens,
    confirmDeposit,
    confirmWithdraw,
});
