import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ClassName from 'classnames';
import CircularProgress from './components/CircularProgress';

const Home = lazy(() => import('./containers/Home').then().catch());
const NavBar = lazy(() => import('./containers/NavBar').then().catch());
const VideoAsset = lazy(() => import('./containers/CreateAssets/Video').then().catch());
const Sidebar = lazy(() => import('./containers/SideBar').then().catch());
const Library = lazy(() => import('./containers/Library').then().catch());
const Dashboard = lazy(() => import('./containers/Dashboard').then().catch());
const Account = lazy(() => import('./containers/Account').then().catch());
const CollectionPage = lazy(() => import('./containers/CollectionPage').then().catch());
const CreateCollection = lazy(() => import('./containers/CreateCollection').then().catch());
const ConnectAccount = lazy(() => import('./containers/ConnectAccount').then().catch());
const InteractiveVideos = lazy(() => import('./containers/InteractiveVideos').then().catch());
const InteractiveVideo = lazy(() => import('./containers/InteractiveVideo').then().catch());

const ITCModule = lazy(() => import('./containers/ITCModule').then().catch());
const ITCModuleCreate = lazy(() => import('./containers/ITCModule/Create').then().catch());
const ITCModuleStats = lazy(() => import('./containers/ITCModule/Stats').then().catch());

const routes = [{
    path: '/',
    component: ConnectAccount,
}];

const protectedRoutes = [{
    path: '/home',
    component: Home,
}, {
    path: '/create-asset',
    component: VideoAsset,
}, {
    path: '/create-asset/:type/:id',
    component: VideoAsset,
}, {
    path: '/library',
    component: Library,
}, {
    path: '/dashboard',
    component: Dashboard,
}, {
    path: '/createCollection',
    component: CreateCollection,
}, {
    path: '/createCollection/:collectionID',
    component: CreateCollection,
}, {
    path: '/account',
    component: Account,
}, {
    path: '/library/:collectionID',
    component: CollectionPage,
}, {
    path: '/interactive-videos',
    component: InteractiveVideos,
}, {
    path: '/interactive-videos/create/:NftID',
    component: InteractiveVideo,
}, {
    path: '/interactive-videos/:interactiveVideoID',
    component: InteractiveVideo,
}, {
    path: '/campaign',
    component: ITCModule,
}, {
    path: '/campaign/create',
    component: ITCModuleCreate,
}, {
    path: '/campaign/stats',
    component: ITCModuleStats,
}];

const Router = (props) => {
    const { location } = props;

    const token = localStorage.getItem('acToken_of_studio');

    useEffect(() => {
        const showBackground = location && location.pathname &&
            protectedRoutes.filter((value) => {
                return location.pathname.split('/')[1] === value.path.split('/')[1];
            });

        if (showBackground.length && location.pathname !== '/home') {
            document.body.classList.add('background');
        } else {
            document.body.className = '';
        }
    }, [location]);

    const showNavBar = props.location && props.location.pathname &&
        protectedRoutes.filter((value) => {
            return props.location.pathname.split('/')[1] === value.path.split('/')[1];
        });

    return (
        <div className="main_content">
            <Suspense fallback={<CircularProgress/>}>
                {showNavBar.length > 0 && token
                    ? <NavBar hideTabs={props.location.pathname === '/home'}/>
                    : null}
                <div className={ClassName('content_div',
                    showNavBar.length > 0 && props.location.pathname !== '/home' ? 'side_bar_content' : '')}>
                    {showNavBar.length > 0 && props.location.pathname !== '/home' && token
                        ? <Sidebar/>
                        : null}
                    <div
                        className="router_content scroll_bar"
                        id="scroll-bar">
                        <Switch>
                            {protectedRoutes.map((route) =>
                                <Route
                                    key={route.path}
                                    exact
                                    component={route.component}
                                    path={route.path}/>,
                            )}
                            {routes.map((route) =>
                                <Route
                                    key={route.path}
                                    exact
                                    component={route.component}
                                    path={route.path}/>,
                            )}
                            <Route
                                exact
                                component={ConnectAccount}
                                path="*"/>
                        </Switch>
                    </div>
                </div>
            </Suspense>
        </div>
    );
};

Router.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export default withRouter(Router);
