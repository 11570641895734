export const CREATE_POLL_DIALOG_SHOW = 'CREATE_POLL_DIALOG_SHOW';
export const CREATE_POLL_DIALOG_HIDE = 'CREATE_POLL_DIALOG_HIDE';

export const POLL_OPTION_SET = 'POLL_OPTION_SET';
export const POLL_QUESTION_SET = 'POLL_QUESTION_SET';
export const POLL_DESCRIPTION_SET = 'POLL_DESCRIPTION_SET';
export const POLL_OPTIONS_SET = 'POLL_OPTIONS_SET';
export const INCLUDE_INFO_SET = 'INCLUDE_INFO_SET';
export const EXCLUDE_INFO_SET = 'EXCLUDE_INFO_SET';
export const POLL_START_DATE_SET = 'POLL_START_DATE_SET';
export const POLL_END_DATE_SET = 'POLL_END_DATE_SET';
