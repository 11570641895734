import {
    CLAIM_TYPE_TAB_SET,
    CLEAR_ITC_MODULE_SET,
    DESCRIPTION_VALUE_SET,
    DISTRIBUTION_TYPE_TAB_SET,
    FT_AMOUNT_VALUE_SET,
    FT_END_DATE_SET,
    FT_TOKEN_VALUE_SET,
    INPUT_COLLECTION_VALUE_SET,
    INTERACTION_TYPE_TAB_SET,
    ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_ERROR,
    ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_SUCCESS,
    ITC_CAMPAIGNS_LISTING_FETCH_ERROR,
    ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS,
    ITC_CAMPAIGNS_LIVE_LISTING_FETCH_ERROR,
    ITC_CAMPAIGNS_LIVE_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_LIVE_LISTING_FETCH_SUCCESS,
    ITC_CAMPAIGNS_STATS_FETCH_ERROR,
    ITC_CAMPAIGNS_STATS_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_STATS_FETCH_SUCCESS,
    ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_ERROR,
    ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_SUCCESS,
    ITC_DEPOSIT_AMOUNT_SET,
    ITC_DEPOSIT_DIALOG_HIDE,
    ITC_DEPOSIT_DIALOG_SHOW,
    ITC_LIST_SEARCH_VALUE_SET,
    ITC_MODULE_TAB_VALUE_SET,
    ITC_STOP_DIALOG_HIDE,
    ITC_STOP_DIALOG_SHOW,
    MAX_ALLOWED_CLAIMS_VALUE_SET,
    NFT_DESCRIPTION_VALUE_SET,
    NFT_EXTENSIBILITY_SET,
    NFT_NSFW_SET,
    NFT_PREVIEW_IMAGE_SET,
    NFT_ROYALTY_SHARE_SET,
    NFT_SELECT_COLLECTIONS_SET,
    NFT_SOURCE_FILE_SET,
    NFT_TITLE_VALUE_SET,
    NFT_TRANSFERABILITY_SET,
    SCHEDULE_END_DATE_SET,
    SCHEDULE_START_DATE_SET,
    SEARCH_INPUT_COLLECTION_VALUE_SET,
    STATS_SEARCH_VALUE_SET,
    STATS_SORT_BY_VALUE_SET,
    TITLE_VALUE_SET,
    TOKENS_PER_CLAIM_VALUE_SET,
} from '../constants/itcModule';
import { combineReducers } from 'redux';
import { DEFAULT_SKIP, DEFAULT_LIMIT } from '../config';

const tabValue = (state = {
    value: 'all',
}, action) => {
    switch (action.type) {
    case ITC_MODULE_TAB_VALUE_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: 'all',
        };
    default:
        return state;
    }
};

const searchValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ITC_LIST_SEARCH_VALUE_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const interactionTypeTab = (state = {
    value: 'verify',
}, action) => {
    switch (action.type) {
    case INTERACTION_TYPE_TAB_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: 'verify',
        };
    default:
        return state;
    }
};

const claimTypeTab = (state = {
    value: 'ft',
}, action) => {
    switch (action.type) {
    case CLAIM_TYPE_TAB_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: 'ft',
        };
    default:
        return state;
    }
};

const title = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case TITLE_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const description = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case DESCRIPTION_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const scheduleStartDate = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case SCHEDULE_START_DATE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const scheduleEndDate = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case SCHEDULE_END_DATE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const inputCollection = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case INPUT_COLLECTION_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const searchInputCollection = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SEARCH_INPUT_COLLECTION_VALUE_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const tokensPerClaim = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case TOKENS_PER_CLAIM_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const maxAllowedClaims = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MAX_ALLOWED_CLAIMS_VALUE_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const ftToken = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case FT_TOKEN_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const ftAmount = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case FT_AMOUNT_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const distributionTypeTab = (state = {
    value: 'instant',
}, action) => {
    switch (action.type) {
    case DISTRIBUTION_TYPE_TAB_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: 'instant',
        };
    default:
        return state;
    }
};

const ftEndDate = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case FT_END_DATE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const nftTitle = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case NFT_TITLE_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const nftDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case NFT_DESCRIPTION_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const nftSourceFile = (state = {
    value: '',
    valid: true,
    type: null,
}, action) => {
    switch (action.type) {
    case NFT_SOURCE_FILE_SET:
        return {
            value: action.value,
            valid: action.valid,
            type: action.typp,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
            type: null,
        };
    default:
        return state;
    }
};

const nftPreviewImage = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case NFT_PREVIEW_IMAGE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const nftSelectCollections = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case NFT_SELECT_COLLECTIONS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: null,
            valid: true,
        };
    default:
        return state;
    }
};

const nftRoyaltyShare = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case NFT_ROYALTY_SHARE_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const nftTransferability = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case NFT_TRANSFERABILITY_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: true,
        };
    default:
        return state;
    }
};

const nftExtensibility = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case NFT_EXTENSIBILITY_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: true,
        };
    default:
        return state;
    }
};

const nftNSFW = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case NFT_NSFW_SET:
        return {
            value: action.value,
        };
    case CLEAR_ITC_MODULE_SET:
        return {
            value: false,
        };
    default:
        return state;
    }
};

const statsSearchValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case STATS_SEARCH_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const statsSortBy = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case STATS_SORT_BY_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const itcListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
        // if (action.skip === DEFAULT_SKIP) {
        //     return {
        //         ...state,
        //         inProgress: false,
        //         result: action.value,
        //         skip: action.limit - DEFAULT_LIMIT,
        //         limit: DEFAULT_LIMIT,
        //         total: action.total,
        //     };
        // } else {
        //     return {
        //         ...state,
        //         inProgress: false,
        //         result: [...state.result, ...action.value],
        //         skip: action.skip,
        //         limit: action.limit,
        //         total: action.total,
        //     };
        // }
    }
    case ITC_CAMPAIGNS_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcUpcomingListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_CAMPAIGNS_UPCOMING_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcLiveListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_LIVE_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_LIVE_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_CAMPAIGNS_LIVE_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcCompletedListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_CAMPAIGNS_COMPLETED_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcDepositDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_DEPOSIT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case ITC_DEPOSIT_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const itcStopDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_STOP_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case ITC_STOP_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const itcDepositAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ITC_DEPOSIT_AMOUNT_SET:
        return {
            value: action.value,
        };
    case ITC_DEPOSIT_DIALOG_HIDE:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const itcStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            value: {},
        };
    case ITC_CAMPAIGNS_STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ITC_CAMPAIGNS_STATS_FETCH_ERROR:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    searchValue,

    interactionTypeTab,
    claimTypeTab,

    title,
    description,
    scheduleStartDate,
    scheduleEndDate,
    inputCollection,
    searchInputCollection,
    tokensPerClaim,
    maxAllowedClaims,

    ftToken,
    ftAmount,
    distributionTypeTab,
    ftEndDate,

    nftTitle,
    nftDescription,
    nftSourceFile,
    nftPreviewImage,
    nftSelectCollections,
    nftRoyaltyShare,
    nftTransferability,
    nftExtensibility,
    nftNSFW,

    statsSearchValue,
    statsSortBy,

    itcListings,
    itcUpcomingListings,
    itcLiveListings,
    itcCompletedListings,

    itcDepositDialog,
    itcStopDialog,
    itcDepositAmount,

    itcStats,
});
